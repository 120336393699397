import React from "react"
import { Container, Grid } from '@material-ui/core'

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
import Onecol from '../components/onecol'
import Quote from '../components/form/quote'
import Map from '../components/map/index'

import Banner from '../components/banner'
import QBanner from '../images/quote-banner.jpg'

const GetQuote = () => (
    <Layout>

    <SEO 
        title="Get a Quote" description="Fill out the form below & let us know what you need. We’ll get back to you as soon as possible."
    />

    <Banner
        title="Get a Quote"
        img={QBanner}
    />    

    <Onecol
        title="We would love the opportunity to assist you"
        description="Please fill in the form below with and we will contact you as soon as possible."
    />

    <Container className="pb-40">
        <Grid items container className="flex justify-center">
            <Grid className="form quote max-auto">
                <Quote />
            </Grid>
        </Grid>
    </Container>

    <Map />

    </Layout>
)

export default GetQuote